'use client';

import { type Material, getMaterialMainImage } from '@afishauz/api/materials';
import { Card } from '@afishauz/core/ui/card';
import { EventSchedule } from '@afishauz/core/ui/event-schedule';
import { SectionTitle } from '@afishauz/core/ui/section-title';
import { Box } from '@afishauz/ui-kit/box';
import { CardMeta, CardTitle } from '@afishauz/ui-kit/card';
import { Container } from '@afishauz/ui-kit/container';
import {
  DelimitedList,
  DelimitedListItem,
} from '@afishauz/ui-kit/delimited-list';
import { Carousel, CarouselItem } from '@afishauz/ui-kit/desktop-carousel';
import { useTranslations } from 'next-intl';

interface Props {
  expected: Material[];
}

export function Expected({ expected }: Props) {
  const t = useTranslations('home');

  return (
    <Box component='section' py='xl'>
      <Container>
        <SectionTitle>{t('expected_events')}</SectionTitle>
        <Box pt='sm' pb='none'>
          <Carousel
            slidesPerView={3}
            controlsOffsetY={95}
            gap={36}
            slidesPerGroup={2}
          >
            {expected.map(material => {
              const place = material.eventSchedules[0]?.place?.name;
              return (
                <CarouselItem key={material['@id']}>
                  <Card
                    link={{
                      href: material.permalinkCanonical,
                      native: material.tildaSubstitute,
                    }}
                    img={{
                      src: getMaterialMainImage(material, 'medium'),
                      alt: material.title,
                    }}
                    size='lg'
                  >
                    <CardTitle title={material.title}>
                      {material.title}
                    </CardTitle>
                    <CardMeta>
                      <DelimitedList>
                        <DelimitedListItem>
                          <EventSchedule schedules={material.eventSchedules} />
                        </DelimitedListItem>
                        {place && (
                          <DelimitedListItem>{place}</DelimitedListItem>
                        )}
                      </DelimitedList>
                    </CardMeta>
                  </Card>
                </CarouselItem>
              );
            })}
          </Carousel>
        </Box>
      </Container>
    </Box>
  );
}

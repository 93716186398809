'use client';

import {
  type MaterialCollection,
  getMaterialMainImage,
} from '@afishauz/api/materials';
import type { Section } from '@afishauz/api/sections';
import { useNewsAndReviews } from '@afishauz/core/pages/shared';
import { Card } from '@afishauz/core/ui/card';
import { CardsGrid } from '@afishauz/core/ui/cards-grid';
import { LoadingButton } from '@afishauz/core/ui/loading-button';
import { PublishedAt } from '@afishauz/core/ui/published-at';
import { SectionTitle } from '@afishauz/core/ui/section-title';
import { VideoBadge } from '@afishauz/core/ui/video-badge';
import { useLocale } from '@afishauz/core/utils/i18n';
import { Box } from '@afishauz/ui-kit/box';
import { CardMeta, CardTitle } from '@afishauz/ui-kit/card';
import { Container } from '@afishauz/ui-kit/container';
import { useTranslations } from 'next-intl';

interface Props {
  initialData: MaterialCollection;
  section?: Section;
  isOnHomepage?: boolean;
}

export function NewsAndReviews({ section, isOnHomepage, initialData }: Props) {
  const t = useTranslations('common');
  const locale = useLocale();
  const { list, isValidating, getNextPage, hasMoreItems } = useNewsAndReviews({
    section,
    isOnHomepage,
    initialData,
    locale,
  });

  return (
    <Box component='section' py='xl'>
      <Container>
        <SectionTitle>{t('templates.news_and_reviews')}</SectionTitle>
        <Box pt='sm' pb='none'>
          <CardsGrid>
            {list.map(material => (
              <Card
                link={{
                  href: material.permalinkCanonical,
                  native: material.tildaSubstitute,
                }}
                img={{
                  src: getMaterialMainImage(material, 'medium'),
                  alt: material.title,
                }}
                size='lg'
                badges={[
                  {
                    component: material.type === 'VIDEO' && (
                      <VideoBadge size='md' />
                    ),
                  },
                ]}
                key={material['@id']}
              >
                <CardTitle title={material.title}>{material.title}</CardTitle>
                <CardMeta>
                  <PublishedAt date={new Date(material.publishedAt)} />
                </CardMeta>
              </Card>
            ))}
          </CardsGrid>
          {hasMoreItems && (
            <LoadingButton
              loading={isValidating}
              className='mt-6'
              onClick={getNextPage}
              disabled={isValidating}
              size='lg'
            />
          )}
        </Box>
      </Container>
    </Box>
  );
}

import { Youtube } from '@afishauz/icons';
import { type VariantProps, cva } from 'class-variance-authority';

const badge = cva(['text-[#ff0000] h-auto'], {
  variants: {
    size: {
      sm: ['w-6'],
      md: ['w-8'],
      lg: ['w-10'],
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export type VideoBadgeProps = VariantProps<typeof badge>;

export function VideoBadge({ size }: VideoBadgeProps) {
  return <Youtube className={badge({ size })} />;
}

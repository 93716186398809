import dayjs from 'dayjs';
import { useTranslations } from 'next-intl';
import { useDateTranslator } from '../utils/date';

interface Props {
  date: Date;
}

export function PublishedAt({ date }: Props) {
  const t = useTranslations('common');
  const { format } = useDateTranslator();

  return (
    <>
      {t('elements.published_at', {
        date: format(
          date,
          dayjs().isSame(new Date(date), 'year') ? 'D MMMM' : 'D MMMM YYYY',
        ),
      })}
    </>
  );
}

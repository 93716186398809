'use client';

import { type Video, getVideoLink, getVideoPoster } from '@afishauz/api/videos';
import { Card } from '@afishauz/core/ui/card';
import { SectionTitle } from '@afishauz/core/ui/section-title';
import { TicketBadge } from '@afishauz/core/ui/ticket-badge';
import { VideoRatingBadge } from '@afishauz/core/ui/video-rating-badge';
import { Box } from '@afishauz/ui-kit/box';
import { CardMeta, CardTitle } from '@afishauz/ui-kit/card';
import { Container } from '@afishauz/ui-kit/container';
import { Carousel, CarouselItem } from '@afishauz/ui-kit/desktop-carousel';
import { useTranslations } from 'next-intl';

interface Props {
  videos: Video[];
}

export function Cinema({ videos }: Props) {
  const t = useTranslations();

  return (
    <Box component='section' py='xl'>
      <Container>
        <SectionTitle href='/cinema'>{t('home.cinema')}</SectionTitle>
        <Carousel
          slidesPerView={6}
          slidesPerGroup={5}
          gap={24}
          controlsOffsetY={94}
        >
          {videos.map(video => (
            <CarouselItem key={video['@id']}>
              <Card
                link={{ href: getVideoLink(video) }}
                img={{ src: getVideoPoster(video, 'small'), alt: video.title }}
                badges={[
                  {
                    component: (
                      <span className='flex gap-x-1'>
                        {video.showsTicketsAvailable && (
                          <TicketBadge
                            title={t('common.elements.tickets.long')}
                          />
                        )}
                        <VideoRatingBadge rating={Number(video.imdbRating)} />
                      </span>
                    ),
                  },
                ]}
                orientation='portrait'
              >
                <CardTitle title={video.title}>{video.title}</CardTitle>
                <CardMeta>{video.genres[0]?.name}</CardMeta>
              </Card>
            </CarouselItem>
          ))}
        </Carousel>
      </Container>
    </Box>
  );
}

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cx } from 'class-variance-authority';
import useEmblaCarousel from 'embla-carousel-react';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
import {
  type ComponentPropsWithoutRef,
  type PropsWithChildren,
  createContext,
  use,
} from 'react';

export interface CarouselProps extends PropsWithChildren {
  gap?: number;
  slidesPerGroup?: number;
  slidesPerView?: number | 'auto';
  loop?: boolean;
  freeMode?: boolean;
  controlsOffsetY?: number;
}

const CarouselContext = createContext<
  Pick<CarouselProps, 'gap' | 'slidesPerView'>
>({});

export function CarouselItem({ children }: PropsWithChildren) {
  const { gap, slidesPerView } = use(CarouselContext);
  return (
    <li
      className='relative flex-shrink-0 flex-grow-0 min-w-0 max-w-full'
      style={{
        paddingLeft: gap,
        flexBasis:
          slidesPerView === 'auto' ? 'auto' : `calc(100% / ${slidesPerView})`,
      }}
    >
      {children}
    </li>
  );
}

function CarouselControl({
  direction,
  ...rest
}: Omit<ComponentPropsWithoutRef<'button'>, 'className' | 'children'> & {
  direction: 'prev' | 'next';
}) {
  return (
    <button
      {...rest}
      className={cx(
        'absolute top-0 w-16 min-w-[64px] h-16 bg-[#333333] backdrop-blur rounded-full z-10 flex justify-center items-center text-white text-xl cursor-pointer transition-transform hover:scale-110',
        direction === 'prev' ? '-left-8' : '-right-8',
      )}
      aria-label={direction === 'prev' ? 'previous' : 'next'}
    >
      {direction === 'prev' ? (
        <FontAwesomeIcon icon={faChevronLeft} />
      ) : (
        <FontAwesomeIcon icon={faChevronRight} />
      )}
    </button>
  );
}

CarouselControl.displayName = 'CarouselControl';

export function Carousel({
  gap = 0,
  slidesPerGroup = 1,
  slidesPerView = 1,
  loop = false,
  freeMode = false,
  controlsOffsetY,
  children,
}: CarouselProps) {
  const [ref, api] = useEmblaCarousel(
    {
      slidesToScroll: slidesPerGroup,
      align: 'start',
      loop,
      dragFree: freeMode,
    },
    [WheelGesturesPlugin()],
  );

  return (
    <CarouselContext value={{ gap, slidesPerView }}>
      <div className='relative'>
        <div ref={ref} className='relative overflow-hidden'>
          <ul className='flex' style={{ marginLeft: -1 * gap }}>
            {children}
          </ul>
        </div>
        {api &&
          api.slideNodes().length > slidesPerGroup &&
          slidesPerView !== 'auto' &&
          api.slideNodes().length > slidesPerView && (
            <>
              <CarouselControl
                direction='prev'
                style={{ top: controlsOffsetY }}
                onClick={() => {
                  api?.scrollPrev();
                }}
              />
              <CarouselControl
                direction='next'
                style={{ top: controlsOffsetY }}
                onClick={() => {
                  api?.scrollNext();
                }}
              />
            </>
          )}
      </div>
    </CarouselContext>
  );
}
